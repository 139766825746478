import type { APIMethodParams } from '@medizzy/api';
import { fetchLearningCategories } from './fetch-learning-categories.ts';

export async function fetchLearningGlobalMasteryLevel({
  client,
}: APIMethodParams) {
  const categories = await fetchLearningCategories({
    client,
    params: { size: Number.MAX_SAFE_INTEGER },
  });

  const mastery = {
    mcq: { correct: 0, incorrect: 0, total: 0, percent: 0 },
    flashcard: { correct: 0, incorrect: 0, total: 0, percent: 0 },
    total: { correct: 0, incorrect: 0, total: 0, percent: 0 },
  };

  for (const category of categories.content) {
    mastery.mcq.correct += category.mcqInfo?.rightAnswers ?? 0;
    mastery.mcq.incorrect += category.mcqInfo?.wrongAnswers ?? 0;
    mastery.mcq.total += category.mcqInfo?.count ?? 0;
    mastery.mcq.percent = Number(
      (
        ((mastery.mcq.correct + mastery.mcq.incorrect) / mastery.mcq.total) *
        100
      ).toFixed(0),
    );

    mastery.flashcard.correct += category.flashcardsInfo?.rightAnswers ?? 0;
    mastery.flashcard.incorrect += category.flashcardsInfo?.wrongAnswers ?? 0;
    mastery.flashcard.total += category.flashcardsInfo?.count ?? 0;
    mastery.flashcard.percent = Number(
      (
        ((mastery.flashcard.correct + mastery.flashcard.incorrect) /
          mastery.flashcard.total) *
        100
      ).toFixed(0),
    );
  }

  mastery.total.correct = mastery.mcq.correct + mastery.flashcard.incorrect;
  mastery.total.incorrect = mastery.mcq.incorrect + mastery.flashcard.incorrect;
  mastery.total.total = mastery.mcq.total + mastery.flashcard.total;
  mastery.total.percent = Number(
    (
      ((mastery.total.correct + mastery.total.incorrect) /
        mastery.total.total) *
      100
    ).toFixed(0),
  );

  return mastery;
}
