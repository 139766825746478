import { createAPIQueryHooks } from '@medizzy/api';
import { fetchLearningGlobalMasteryLevel } from '../methods/fetch-learning-global-mastery-level.ts';
import { INVALIDATE_ON_ANSWER_KEY } from './utils.ts';

export const [
  useLearningGlobalMasteryLevel,
  useLearningGlobalMasteryLevelSuspense,
] = createAPIQueryHooks({
  queryFn: fetchLearningGlobalMasteryLevel,
  queryKey: ['learning-global-mastery', INVALIDATE_ON_ANSWER_KEY],
});
